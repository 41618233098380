<template>
  <v-banner
    v-if="globalBanner.show"
    single-line
    elevation="8"
    transition="scroll-y-reverse-transition"
  >
    <v-icon
      v-if="globalBanner.icon"
      slot="icon"
      :color="globalBanner.color"
      size="40"
    >
      {{ globalBanner.icon }}
    </v-icon>
    {{ globalBanner.message }}
    <template v-slot:actions>
      <v-btn
        v-if="globalBanner.to"
        :color="globalBanner.color"
        text
        :to="globalBanner.to"
      >
        {{ globalBanner.actionLabel }}
      </v-btn>
      <v-btn
        text
        @click="setGlobalBanner(null)"
      >
        Dismiss
      </v-btn>
    </template>
  </v-banner>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState(['globalBanner'])
  },

  destroyed () {
    this.setGlobalBanner(null)
  },

  methods: {
    ...mapMutations(['setGlobalBanner'])
  }
}
</script>
