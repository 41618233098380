var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.globalBanner.show
    ? _c(
        "v-banner",
        {
          attrs: {
            "single-line": "",
            elevation: "8",
            transition: "scroll-y-reverse-transition"
          },
          scopedSlots: _vm._u(
            [
              {
                key: "actions",
                fn: function() {
                  return [
                    _vm.globalBanner.to
                      ? _c(
                          "v-btn",
                          {
                            attrs: {
                              color: _vm.globalBanner.color,
                              text: "",
                              to: _vm.globalBanner.to
                            }
                          },
                          [
                            _vm._v(
                              "\n      " +
                                _vm._s(_vm.globalBanner.actionLabel) +
                                "\n    "
                            )
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "v-btn",
                      {
                        attrs: { text: "" },
                        on: {
                          click: function($event) {
                            return _vm.setGlobalBanner(null)
                          }
                        }
                      },
                      [_vm._v("\n      Dismiss\n    ")]
                    )
                  ]
                },
                proxy: true
              }
            ],
            null,
            false,
            2775462682
          )
        },
        [
          _vm.globalBanner.icon
            ? _c(
                "v-icon",
                {
                  attrs: {
                    slot: "icon",
                    color: _vm.globalBanner.color,
                    size: "40"
                  },
                  slot: "icon"
                },
                [_vm._v("\n    " + _vm._s(_vm.globalBanner.icon) + "\n  ")]
              )
            : _vm._e(),
          _vm._v("\n  " + _vm._s(_vm.globalBanner.message) + "\n  ")
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }